<template>
  <VForm :value="value" @input="$emit('input', $event)">
    <VCard>
      <div class="subtitle-2 text-center">透析過程</div>
      <VDivider />
      <VSimpleTable dense height="calc(100vh - 573px)" fixed-header>
        <template #default>
          <thead>
            <tr>
              <th />
              <th
                v-for="(label, key) in labels"
                :key="key"
                class="caption"
                style="white-space: pre"
              >
                {{ label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in inputs" :key="i" :class="`tr-${i}`">
              <th>
                <VBtn
                  v-if="i !== 0 && i !== indexOfAfterHD && !(i === 1 && indexOfAfterHD < 3)"
                  icon
                  x-small
                  @click="deleteInputsRow(i)"
                >
                  <VIcon color="error">mdi-close</VIcon>
                </VBtn>
                <span class="caption">{{ rowHeaders[i] }}</span>
              </th>
              <td>
                <XTimePicker v-model="row.time" outlined />
              </td>
              <td>
                <XTextField
                  v-model="row.systolicBloodPressure"
                  type="number"
                  outlined
                  :rules="rules.systolicBloodPressure"
                />
              </td>
              <td>
                <XTextField
                  v-model="row.diastolicBloodPressure"
                  type="number"
                  outlined
                  :rules="rules.diastolicBloodPressure"
                />
              </td>
              <td>
                <XTextField v-model="row.pulse" type="number" outlined :rules="rules.pulse" />
              </td>
              <template v-if="i > 0 && i < indexOfAfterHD">
                <td>
                  <XTextField
                    v-model="row.bloodFlowRate"
                    type="number"
                    outlined
                    :rules="rules.bloodFlowRate"
                  />
                </td>
                <td>
                  <XTextField
                    v-model="row.venousPressure"
                    type="number"
                    outlined
                    :rules="rules.venousPressure"
                  />
                </td>
                <td>
                  <XTextField
                    v-model="row.transmembranePressure"
                    type="number"
                    outlined
                    :rules="rules.transmembranePressure"
                  />
                </td>
                <td>
                  <XTextField
                    v-model="row.accumulatedHeparin"
                    type="number"
                    outlined
                    :rules="rules.accumulatedHeparin"
                  />
                </td>
                <td>
                  <XTextField
                    v-model="row.fluidTemperature"
                    type="number"
                    outlined
                    :rules="rules.fluidTemperature"
                  />
                </td>
                <td>
                  <XTextField
                    v-model="row.ultrafiltrationRate"
                    type="number"
                    outlined
                    :rules="rules.ultrafiltrationRate"
                  />
                </td>
                <td>
                  <XTextField
                    v-model="row.totalUltrafiltration"
                    type="number"
                    outlined
                    :rules="rules.totalUltrafiltration"
                  />
                </td>
                <td>
                  <XTextField
                    v-model="row.conductivity"
                    type="number"
                    outlined
                    :rules="rules.conductivity"
                  />
                </td>
                <td>
                  <XTextField
                    v-model="row.dialysateFlowRate"
                    type="number"
                    outlined
                    :rules="rules.dialysateFlowRate"
                  />
                </td>
                <td v-if="isTreatmentHDF">
                  <XTextField
                    v-model="row.substitutionVolume"
                    type="number"
                    outlined
                    :rules="rules.substitutionVolume"
                  />
                </td>
                <td>
                  <VMenu
                    v-model="menus[i].measures"
                    offset-y
                    max-width="240px"
                    :close-on-content-click="false"
                  >
                    <template #activator="{ on, attrs }">
                      <div
                        v-ripple
                        class="d-flex flex-wrap justify-center"
                        style="height: 100%"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <VChip
                          v-for="(measure, j) in row.measures"
                          :key="j"
                          label
                          x-small
                          class="info align-self-center"
                          style="padding: 2px; margin: 2px"
                        >
                          {{ measure }}
                        </VChip>
                      </div>
                    </template>
                    <VCard class="px-2 py-4">
                      <VRow>
                        <VCol>
                          <XSelect
                            v-model="row.measures"
                            label="設定"
                            multiple
                            :items="options.measures"
                          />
                        </VCol>
                      </VRow>
                      <VRow>
                        <VCol>
                          <XTextField
                            value=""
                            label="Levophed"
                            type="number"
                            suffix="mL/hr"
                            :rules="rules.temp"
                          />
                        </VCol>
                        <VCol>
                          <XTextField
                            value=""
                            label="Dopamin"
                            type="number"
                            suffix="mL/hr"
                            :rules="rules.temp"
                          />
                        </VCol>
                      </VRow>
                    </VCard>
                  </VMenu>
                </td>
                <td>
                  <VMenu
                    v-model="menus[i].processingNote"
                    offset-y
                    max-width="240px"
                    :close-on-content-click="false"
                  >
                    <template #activator="{ on, attrs }">
                      <div
                        v-ripple
                        class="d-flex justify-center"
                        style="height: 100%"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span class="caption align-self-center">{{ row.processingNote }}</span>
                      </div>
                    </template>
                    <VCard class="px-2 py-4">
                      <VRow>
                        <VCol>
                          <XCombobox
                            v-model="row.processingNote"
                            label="設定"
                            clearable
                            :items="options.processingNote"
                          />
                        </VCol>
                      </VRow>
                    </VCard>
                  </VMenu>
                </td>
              </template>
              <td v-else :colspan="isTreatmentHDF ? 12 : 11" class="disabled" />
            </tr>
          </tbody>
          <div class="tbody-bottom" />
        </template>
      </VSimpleTable>
      <VBtn icon x-small absolute style="top: 9px; right: 30px" @click="addInputsRow(true, false)">
        <VIcon>mdi-plus</VIcon>
      </VBtn>
      <VBtn icon x-small absolute style="top: 9px; right: 9px" @click="addInputsRow(true, true)">
        <VIcon>mdi-table-row-plus-after</VIcon>
      </VBtn>
    </VCard>
  </VForm>
</template>

<script>
import Vue from 'vue';

import * as inputRules from '@/utils/inputRules';

import { Treatment } from '@/models/NursingRecord';

import { measureOptions, processingNoteOptions } from './options';

const labels = {
  time: '時間',
  systolicBloodPressure: '收縮壓\n(mmHg)',
  diastolicBloodPressure: '舒張壓\n(mmHg)',
  pulse: '脈搏\n(bpm)',
  bloodFlowRate: '血液流速\n(mL/min)',
  venousPressure: '靜脈壓\n(mmHg)',
  transmembranePressure: '膜上壓\n(mmHg)',
  accumulatedHeparin: '累積肝素\n(U)',
  fluidTemperature: '液溫\n(℃)',
  ultrafiltrationRate: 'UF率\n(L/hr)',
  totalUltrafiltration: '總UF\n(L)',
  conductivity: '傳導度\n(mS/cm)',
  dialysateFlowRate: 'DF\n(mL/min)',
  substitutionVolume: 'Sub vol\n(L)',
  measures: '處置',
  processingNote: '備註',
};

const inputsTemplate = {
  time: null,
  systolicBloodPressure: null,
  diastolicBloodPressure: null,
  pulse: null,
  bloodFlowRate: null,
  venousPressure: null,
  transmembranePressure: null,
  accumulatedHeparin: null,
  fluidTemperature: null,
  ultrafiltrationRate: null,
  totalUltrafiltration: null,
  conductivity: null,
  dialysateFlowRate: null,
  substitutionVolume: null,
  measures: [],
  processingNote: null,
};

const menusTemplate = {
  measures: false,
  processingNote: false,
};

export default Vue.extend({
  name: 'HDProcessForm',
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, required: true },
  },
  data: () => ({
    inputs: null,
    options: {
      measures: measureOptions,
      processingNote: processingNoteOptions,
    },
    rules: {
      systolicBloodPressure: [inputRules.required, (v) => inputRules.numberString(v, 0)],
      diastolicBloodPressure: [inputRules.required, (v) => inputRules.numberString(v, 0)],
      pulse: [inputRules.required, (v) => inputRules.numberString(v, 0)],
      bloodFlowRate: [inputRules.required, (v) => inputRules.numberString(v, 0)],
      venousPressure: [inputRules.required, (v) => inputRules.numberString(v, 0)],
      transmembranePressure: [inputRules.required, (v) => inputRules.numberString(v, 0)],
      accumulatedHeparin: [inputRules.required, (v) => inputRules.numberString(v, 0)],
      fluidTemperature: [inputRules.required, (v) => inputRules.numberString(v, 1)],
      ultrafiltrationRate: [inputRules.required, (v) => inputRules.numberString(v, 2)],
      totalUltrafiltration: [inputRules.required, (v) => inputRules.numberString(v, 2)],
      conductivity: [inputRules.required, (v) => inputRules.numberString(v, 1)],
      dialysateFlowRate: [inputRules.required, (v) => inputRules.numberString(v, 2)],
      substitutionVolume: [inputRules.required, (v) => inputRules.numberString(v, 0)],

      temp: [(v) => inputRules.numberString(v, 1)],
    },
    menus: [],
  }),
  computed: {
    labels() {
      if (this.isTreatmentHDF) return labels;
      return _.omit(labels, ['substitutionVolume']);
    },
    isTreatmentHDF() {
      return this.data.hdOrientation.treatment === Treatment.HDF;
    },
    indexOfAfterHD() {
      return this.inputs.findIndex((row) => row.processingNote === '洗後');
    },
    rowHeaders() {
      return this.inputs.reduce((result, row, i) => {
        if (i === 0 || i === this.indexOfAfterHD) result.push(row.processingNote);
        else if (i < this.indexOfAfterHD) result.push(`${i}`);
        else result.push(`${i - this.indexOfAfterHD}`);
        return result;
      }, []);
    },
  },
  watch: {
    inputs: {
      handler: 'updateModelFromInputs',
      deep: true,
    },
  },
  created() {
    this.inputs = this.loadModelToInputs(this.data.hdProcess);
    if (this.inputs.length === 0) {
      _.times(6, () => this.addInputsRow(false));
      _.head(this.inputs).time = this.dayjs().format('HH:mm');
      _.head(this.inputs).processingNote = '洗前';
      _.last(this.inputs).processingNote = '洗後';
    } else {
      this.menus = _.times(this.inputs.length, () => _.cloneDeep(menusTemplate));
    }
  },
  methods: {
    loadModelToInputs(model) {
      const inputs = _.cloneDeep(model);
      _.zip(inputs, model).forEach(([i, m]) => {
        i.systolicBloodPressure = m.systolicBloodPressure?.toFixed(0);
        i.diastolicBloodPressure = m.diastolicBloodPressure?.toFixed(0);
        i.pulse = m.pulse?.toFixed(0);
        i.bloodFlowRate = m.bloodFlowRate?.toFixed(0);
        i.venousPressure = m.venousPressure?.toFixed(0);
        i.transmembranePressure = m.transmembranePressure?.toFixed(0);
        i.accumulatedHeparin = m.accumulatedHeparin?.toFixed(0);
        i.fluidTemperature = m.fluidTemperature?.toFixed(1);
        i.ultrafiltrationRate = m.ultrafiltrationRate?.toFixed(2);
        i.totalUltrafiltration = m.totalUltrafiltration?.toFixed(2);
        i.conductivity = m.conductivity?.toFixed(1);
        i.dialysateFlowRate = m.dialysateFlowRate?.toFixed(2);
        i.substitutionVolume = m.substitutionVolume?.toFixed(0);
      });
      return inputs;
    },
    updateModelFromInputs(inputs) {
      const model = _.cloneDeep(inputs);
      _.zip(model, inputs).forEach(([m, i]) => {
        m.systolicBloodPressure = parseInt(i.systolicBloodPressure, 10);
        m.diastolicBloodPressure = parseInt(i.diastolicBloodPressure, 10);
        m.pulse = parseInt(i.pulse, 10);
        m.bloodFlowRate = parseInt(i.bloodFlowRate, 10);
        m.venousPressure = parseInt(i.venousPressure, 10);
        m.transmembranePressure = parseInt(i.transmembranePressure, 10);
        m.accumulatedHeparin = parseInt(i.accumulatedHeparin, 10);
        m.fluidTemperature = parseFloat(i.fluidTemperature);
        m.ultrafiltrationRate = parseFloat(i.ultrafiltrationRate);
        m.totalUltrafiltration = parseFloat(i.totalUltrafiltration);
        m.conductivity = parseFloat(i.conductivity);
        m.dialysateFlowRate = parseFloat(i.dialysateFlowRate);
        m.substitutionVolume = parseInt(i.substitutionVolume, 10);
      });
      this.$emit('update', model);
    },
    addInputsRow(withUX, isAfterHD) {
      const index = isAfterHD ? this.inputs.length : this.indexOfAfterHD;
      this.inputs.splice(index, 0, _.cloneDeep(inputsTemplate));
      this.menus.splice(index, 0, _.cloneDeep(menusTemplate));
      if (withUX) {
        this.inputs[index].time = this.dayjs().format('HH:mm');
        this.$vuetify.goTo(`.tbody-bottom`, { container: '.v-data-table__wrapper' });
      }
    },
    deleteInputsRow(index) {
      this.inputs.splice(index, 1);
      this.menus.splice(index, 1);
    },
  },
});
</script>
