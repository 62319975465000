<template>
  <VForm :value="value" @input="$emit('input', $event)">
    <VCard>
      <div class="subtitle-2 text-center">透析時間範圍</div>
      <VDivider />
      <VRow>
        <VCol>
          <XTimePicker v-model="inputs.beginTime" />
        </VCol>
        <VCol cols="auto" style="padding-top: 6px">
          <VTooltip bottom :disabled="isTimeRangeValid">
            <template #activator="{ on, attrs }">
              <span :class="isTimeRangeValid ? '' : 'error--text'" v-bind="attrs" v-on="on">
                ～
              </span>
            </template>
            無效的時間範圍
          </VTooltip>
        </VCol>
        <VCol>
          <XTimePicker v-model="inputs.endTime" />
        </VCol>
      </VRow>
    </VCard>
  </VForm>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'HDTimeRangeForm',
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, required: true },
  },
  data: () => ({
    inputs: null,
  }),
  computed: {
    isTimeRangeValid() {
      const beginTime = this.dayjs(`1901-01-01 ${this.inputs.beginTime}`);
      const endTime = this.dayjs(`1901-01-01 ${this.inputs.endTime}`);
      return beginTime.isBefore(endTime);
    },
  },
  watch: {
    inputs: {
      handler: 'updateModelFromInputs',
      deep: true,
    },
  },
  created() {
    this.inputs = this.loadModelToInputs(this.data.hdTimeRange);
  },
  methods: {
    loadModelToInputs(model) {
      const inputs = _.cloneDeep(model);
      return inputs;
    },
    updateModelFromInputs(inputs) {
      const model = _.cloneDeep(inputs);
      this.$emit('update', model);
    },
  },
});
</script>
