<template>
  <VForm :value="value" @input="$emit('input', $event)">
    <VCard>
      <div class="subtitle-2 text-center">HDF</div>
      <VDivider />
      <VRow>
        <VCol>
          <XSelect v-model="inputs.dilutionMethod" :items="options.dilutionMethod" />
        </VCol>
        <VCol>
          <XTextField
            v-model="inputs.infusionRate"
            type="number"
            suffix="L/分"
            :rules="rules.infusionRate"
          />
        </VCol>
      </VRow>
    </VCard>
  </VForm>
</template>

<script>
import Vue from 'vue';

import * as inputRules from '@/utils/inputRules';

import { dilutionMethodOptions } from './options';

export default Vue.extend({
  name: 'HemodiafiltrationForm',
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, required: true },
  },
  data: () => ({
    inputs: null,
    options: {
      dilutionMethod: dilutionMethodOptions,
    },
    rules: {
      infusionRate: [inputRules.required, (v) => inputRules.numberString(v, 0)],
    },
  }),
  watch: {
    inputs: {
      handler: 'updateModelFromInputs',
      deep: true,
    },
  },
  created() {
    this.inputs = this.loadModelToInputs(this.data.hemodiafiltration);
  },
  methods: {
    loadModelToInputs(model) {
      const inputs = _.cloneDeep(model);
      inputs.infusionRate = model.afterHDFromLast?.toFixed(0);
      return inputs;
    },
    updateModelFromInputs(inputs) {
      const model = _.cloneDeep(inputs);
      model.afterHDFromLast = parseInt(inputs.infusionRate, 10);
      this.$emit('update', model);
    },
  },
});
</script>
