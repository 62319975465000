<template>
  <VForm :value="value" @input="$emit('input', $event)">
    <VCard>
      <VRow>
        <VCol class="col-180-24">
          <XTextField
            v-model="inputs.totalHDTimes"
            type="number"
            label="總透析次數"
            suffix="次"
            readonly
            :rules="rules.totalHDTimes"
          />
        </VCol>
        <VCol class="col-180-33">
          <XSelect v-model="inputs.treatment" label="治療項目" :items="options.treatment" />
        </VCol>
        <VCol class="col-180-36">
          <XSelect
            v-model="inputs.weightMeasurementMethod"
            label="體重量測方式"
            :items="options.weightMeasurementMethod"
          />
        </VCol>
        <VCol class="col-180-24">
          <XSelect v-model="inputs.consciousness" label="意識狀態" :items="options.consciousness" />
        </VCol>
        <VCol class="col-180-42">
          <VMenu
            v-model="menus.glasgowComaScale"
            :close-on-content-click="false"
            :disabled="inputs.consciousness !== 'others'"
            offset-y
            min-width="240px"
          >
            <template #activator="{ on, attrs, value: isMenuOpen }">
              <VTextField
                v-model="formattedGlasgowComaScale"
                :class="
                  'text-center ' +
                  (isMenuOpen ? 'v-select v-select--is-menu-active' : 'v-autocomplete')
                "
                :disabled="inputs.consciousness !== 'others'"
                dense
                hide-details
                readonly
                append-icon="mdi-menu-down"
                label="GCS"
                :rules="rules.glasgowComaScale"
                v-bind="attrs"
                v-on="on"
                @click:append="menus.glasgowComaScale = !menus.glasgowComaScale"
              />
            </template>
            <!-- FIXME: 暫時使用 style="margin-top: 1px" 修復卡片遮住輸入框底線1像素-->
            <VCard class="px-2 py-4" style="margin-top: 1px">
              <VRow v-for="(option, key) in options.glasgowComaScale" :key="key">
                <VCol>
                  <VSlider
                    v-model="inputs.glasgowComaScale[key]"
                    :label="key[0].toUpperCase()"
                    dense
                    hide-details
                    :tick-labels="option"
                    :max="option.length - 1"
                    track-fill-color="primary lighten-3"
                    :tick-size="0"
                  >
                    <template #append>
                      <span class="v-label" style="margin-top: 2px">
                        {{ options.glasgowComaScale[key][inputs.glasgowComaScale[key]] }}
                      </span>
                    </template>
                  </VSlider>
                </VCol>
              </VRow>
            </VCard>
          </VMenu>
        </VCol>
        <VCol class="col-180-21">
          <XTextField
            v-model="inputs.bodyTemperature"
            type="number"
            label="體溫"
            suffix="℃"
            :rules="rules.bodyTemperature"
          />
        </VCol>
      </VRow>
    </VCard>
  </VForm>
</template>

<script>
import Vue from 'vue';

import * as inputRules from '@/utils/inputRules';

import { Consciousness } from '@/models/NursingRecord';

import {
  consciousnessOptions,
  glasgowComaScaleOptions,
  treatmentOptions,
  weightMeasurementMethodOptions,
} from './options';

export default Vue.extend({
  name: 'HDOrientationForm',
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, required: true },
  },
  data: () => ({
    inputs: null,
    options: {
      treatment: treatmentOptions,
      weightMeasurementMethod: weightMeasurementMethodOptions,
      consciousness: consciousnessOptions,
      glasgowComaScale: glasgowComaScaleOptions,
    },
    rules: {
      totalHDTimes: [inputRules.required, (v) => inputRules.numberString(v, 0)],
      glasgowComaScale: [inputRules.required],
      bodyTemperature: [inputRules.required, (v) => inputRules.numberString(v, 1, [20, 50])],
    },
    menus: {
      glasgowComaScale: false,
    },
  }),
  computed: {
    formattedGlasgowComaScale() {
      if (this.inputs.consciousness !== Consciousness.Others) return '';
      const input = this.inputs.glasgowComaScale;
      if (Object.values(input).some(_.isNil)) return null;
      const option = this.options.glasgowComaScale;
      const gcs = Object.entries(input).reduce((result, [key, value]) => {
        result[key] = option[key][value];
        return result;
      }, {});
      const score = Object.values(gcs).reduce(
        (result, value) => result + (parseInt(value, 10) || 1),
        0,
      );
      return `${score} = E${gcs.eye}V${gcs.verbal}M${gcs.motor}`;
    },
  },
  watch: {
    inputs: {
      handler: 'updateModelFromInputs',
      deep: true,
    },
  },
  created() {
    this.inputs = this.loadModelToInputs(this.data.hdOrientation);
  },
  methods: {
    loadModelToInputs(model) {
      const inputs = _.cloneDeep(model);
      inputs.totalHDTimes = model.totalHDTimes?.toFixed(0);
      const indexOfGCS = { eye: 1, verbal: 3, motor: 5 };
      inputs.glasgowComaScale = Object.entries(this.options.glasgowComaScale).reduce(
        (model, [key, option]) => {
          model[key] = option.indexOf(indexOfGCS[key]);
          if (model[key] < 0) model[key] = null;
          return model;
        },
        {},
      );
      inputs.bodyTemperature = model.bodyTemperature?.toFixed(1);
      return inputs;
    },
    updateModelFromInputs(inputs) {
      const model = _.cloneDeep(inputs);
      model.totalHDTimes = parseInt(inputs.totalHDTimes, 10);
      // TODO
      model.glasgowComaScale = _.isNil(this.formattedGlasgowComaScale)
        ? null
        : this.formattedGlasgowComaScale.split(' = ')[1];
      model.bodyTemperature = parseFloat(inputs.bodyTemperature);
      this.$emit('update', model);
    },
  },
});
</script>
