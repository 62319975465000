<template>
  <div class="hd-record nursing-record">
    <VContainer fluid>
      <VForm>
        <VContainer>
          <VRow no-gutters>
            <VCol>
              <VRow>
                <VCol class="col-180-69">
                  <PatientProfileForm :data="data" />
                </VCol>
                <VCol class="col-180-33">
                  <HDWhenWhereForm :data="data" />
                </VCol>
                <VCol class="col-180-78">
                  <HDOrientationForm :data="data" @update="data.hdOrientation = $event" />
                </VCol>
              </VRow>
            </VCol>
          </VRow>
          <VRow no-gutters>
            <VCol cols="2">
              <VRow>
                <VCol>
                  <HDTimeRangeForm :data="data" @update="data.hdTimeRange = $event" />
                </VCol>
              </VRow>
              <VRow v-if="isTreatmentHDF">
                <VCol>
                  <HemodiafiltrationForm :data="data" @update="data.hemodiafiltration = $event" />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <VascularAccessForm :data="data" @update="data.vascularAccess = $event" />
                </VCol>
                <VCol>
                  <WoundRecordForm :data="data" @update="data.woundRecord = $event" />
                </VCol>
              </VRow>
            </VCol>
            <VCol cols="10">
              <VRow>
                <VCol cols="7">
                  <HDMachineSetupForm :data="data" @update="data.hdMachineSetup = $event" />
                </VCol>
                <VCol cols="5">
                  <PatientWeightForm :data="data" @update="data.patientWeight = $event" />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <HDProcessForm :data="data" @update="data.hdProcess = $event" />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <HDProcessNoteForm :data="data" @update="data.hdProcessNote = $event" />
                </VCol>
              </VRow>
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VContainer>
    <!-- 右鍵選單 -->
    <!-- <VMenu
      v-model="contextMenuIsShown"
      :position-x="cursor.x"
      :position-y="cursor.y"
      absolute
      offset-y
    >
      <VList>
        <VListItem
          v-for="(item, i) in contextMenuItems"
          :key="i"
          dense
          :disabled="selected === item"
          @click="selected = item"
        >
          <VListItemTitle>{{ item }}</VListItemTitle>
        </VListItem>
      </VList>
    </VMenu> -->
  </div>
</template>

<script>
import Vue from 'vue';

import HDMachineSetupForm from '@/components/HDRecord/NursingRecord/HDMachineSetupForm.vue';
import HDOrientationForm from '@/components/HDRecord/NursingRecord/HDOrientationForm.vue';
import HDProcessForm from '@/components/HDRecord/NursingRecord/HDProcessForm.vue';
import HDProcessNoteForm from '@/components/HDRecord/NursingRecord/HDProcessNoteForm.vue';
import HDTimeRangeForm from '@/components/HDRecord/NursingRecord/HDTimeRangeForm.vue';
import HDWhenWhereForm from '@/components/HDRecord/NursingRecord/HDWhenWhereForm.vue';
import HemodiafiltrationForm from '@/components/HDRecord/NursingRecord/HemodiafiltrationForm.vue';
import PatientProfileForm from '@/components/HDRecord/NursingRecord/PatientProfileForm.vue';
import PatientWeightForm from '@/components/HDRecord/NursingRecord/PatientWeightForm.vue';
import VascularAccessForm from '@/components/HDRecord/NursingRecord/VascularAccessForm.vue';
import WoundRecordForm from '@/components/HDRecord/NursingRecord/WoundRecordForm.vue';

import { Treatment } from '@/models/NursingRecord';

const defaultData = {
  patientProfile: {
    idNumber: 'A123456789',
    fullName: '王小明',
    birthDate: '2021-07-01',
    sex: 1,
    hospitalBedNumber: 'A01',
  },
  hdWhenWhere: {
    hdDate: '2021-07-01',
    hdBedNumber: 'A01',
    hdMachineNumber: '001',
  },
  hdOrientation: {
    totalHDTimes: 10000,
    treatment: Treatment.HD,
    weightMeasurementMethod: null,
    consciousness: null,
    glasgowComaScale: null,
    bodyTemperature: null,
  },
  hdTimeRange: {
    beginTime: null,
    endTime: null,
  },
  hemodiafiltration: {
    dilutionMethod: null,
    infusionRate: null,
  },
  hdMachineSetup: {
    hdFrequency: null,
    hdDuration: null,
    dialysateFlowRate: null,
    typeOfDialyzer: null,
    kIonConcentration: null,
    caIonConcentration: null,
    nameOfHeparin: null,
    loadingDoseOfHeparin: null,
    maintenanceDoseOfHeparin: null,
    primingDoseOfHeparin: null,
    orderOfNoHeparin: null,
    reasonOfNoHeparin: null,
  },
  patientWeight: {
    afterHDFromLast: 60,
    dryWeight: 60,
    withoutClothes: null,
    beforeHD: null,
    expectedDehydration: null,
    includedTypes: [],
    includedWeight: null,
    setDehydration: null,
    afterHD: null,
    actualDehydration: null,
  },
  vascularAccess: {
    bodyParts: [],
    typeOfVascular: null,
    sizeOfPunctureNeedle: null,
    specificationsOfDLC: [],
    statusOfThrill: null,
    statusOfBruit: null,
    evaluationResults: [],
  },
  hdProcess: [],
  hdProcessNote: {
    oxygenDeliveryMethod: null,
    oxygenFlowRate: null,
    bloodOxygenSaturationLevel: null,
    isPipelineSafe: null,
    isBedRailUsed: null,
    akClot: null,
    chamberClot: null,
    processedNotes: [],
    noteFromPhysician: null,
  },
  woundRecord: {
    // TODO
  },
};

export default Vue.extend({
  name: 'HDRecordNursingRecord',
  components: {
    HDMachineSetupForm,
    HDOrientationForm,
    HDProcessForm,
    HDProcessNoteForm,
    HDTimeRangeForm,
    HDWhenWhereForm,
    HemodiafiltrationForm,
    PatientProfileForm,
    PatientWeightForm,
    VascularAccessForm,
    WoundRecordForm,
  },
  data: () => ({
    data: _.cloneDeep(defaultData),
    // cursor: {
    //   x: 0,
    //   y: 0,
    // },
    // contextMenuItems: ['無法量測', '從缺', '取消'],
    // contextMenuIsShown: false,
    // selected: '取消',
  }),
  computed: {
    isTreatmentHDF() {
      return this.data.hdOrientation.treatment === Treatment.HDF;
    },
  },
  methods: {
    // showContextMenu(event) {
    //   event.preventDefault();
    //   this.contextMenuIsShown = false;
    //   this.cursor.x = event.clientX;
    //   this.cursor.y = event.clientY;
    //   this.$nextTick().then(() => {
    //     this.contextMenuIsShown = true;
    //   });
    // },
  },
});
</script>

<style lang="scss">
// ASUS Pro A4321: 19.5吋 1600x 900 devicePixelRatio=1 => 1600x 900
// Apple iPad Pro: 12.9吋 2732×2048 devicePixelRatio=2 => 1366x1024
.hd-record.nursing-record {
  .v-toolbar.v-toolbar--dense {
    z-index: 1;
    .v-toolbar__content {
      padding: 0 3px;
      > * {
        margin: 0 3px;
      }
    }
  }

  > .container {
    max-height: calc(100vh - 144px);
    overflow-y: auto;
    padding: 0px;
    .v-form {
      .container {
        max-width: 1360px;
        width: 1360px;
        padding: 4px;
        .row {
          margin: 0px;
          // &:not(:last-child) {
          //   margin-bottom: 4px;
          // }
          &:not(.no-gutters) .col {
            padding: 4px;
          }
        }

        .v-card {
          padding: 8px;
          .v-divider {
            padding: 4px;
          }

          .row {
            margin: -4px;
            &:not(.no-gutters) .col {
              padding-top: 12px;
            }
          }

          .v-input {
            padding-top: 0px;
            &.v-input--is-readonly:not(.v-autocomplete):not(.v-select) {
              background-color: rgba(0, 0, 0, 0.06);
            }
            &.v-input--hide-label {
              margin-top: -8px;
            }
            .v-chip--select.v-chip.v-size--small {
              border-radius: 6px;
              padding: 0 6px;
              margin: 0 2px;
              font-size: 14px;
            }
          }

          th,
          td {
            &:not(:nth-last-child(-n + 2)) {
              width: 64px;
            }
            &:first-child {
              width: 20px;
            }
            text-align: center;
            padding: 0px;
            .v-input {
              border-radius: 0px;
              &:not(.v-input--is-focused):not(.error--text)
                > .v-input__control
                > .v-input__slot:not(:hover):not(:active)
                > fieldset {
                color: transparent;
              }
            }
          }

          $border-colors: (
            'theme--light': rgba(0, 0, 0, 0.12),
            'theme--dark': rgba(255, 255, 255, 0.12),
          );
          @each $theme, $border-color in $border-colors {
            .#{$theme}.v-data-table > .v-data-table__wrapper > table > {
              thead,
              tbody {
                > tr > {
                  td,
                  th {
                    &:not(:last-child) {
                      border-right: thin solid $border-color;
                    }
                  }
                  td.disabled {
                    background-color: $border-color;
                    border-bottom: none;
                  }
                }

                > tr:hover {
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
