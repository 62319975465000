import * as model from '@/models/NursingRecord';

export const includedTypeOptions = [
  { text: '食物', value: model.IncludedType.Food },
  { text: '水', value: model.IncludedType.Water },
  { text: '藥物', value: model.IncludedType.Medication },
];

export const treatmentOptions = [
  { text: 'HD', value: model.Treatment.HD },
  { text: 'HDF', value: model.Treatment.HDF },
  { text: 'CVVH', value: model.Treatment.CVVH },
  { text: 'SLEDD-f', value: model.Treatment.SLEDDf },
  { text: 'DFPP', value: model.Treatment.DFPP },
  { text: 'PE', value: model.Treatment.PE },
  { text: 'HP', value: model.Treatment.HP },
  { text: 'MARS', value: model.Treatment.MARS },
];

export const weightMeasurementMethodOptions = [
  { text: '無法測量', value: model.WeightMeasurementMethod.Unable },
  { text: '坐秤', value: model.WeightMeasurementMethod.Sitting },
  { text: '床秤', value: model.WeightMeasurementMethod.Lying },
  { text: '吊秤', value: model.WeightMeasurementMethod.Hanging },
];

export const consciousnessOptions = [
  { text: '清醒', value: model.Consciousness.Sober },
  { text: '其他', value: model.Consciousness.Others },
];

export const glasgowComaScaleOptions = {
  eye: ['4', '3', '2', '1', 'c'],
  verbal: ['5', '4', '3', '2', '1', 't', 'e', 'a'],
  motor: ['6', '5', '4', '3', '2', '1'],
};

export const dilutionMethodOptions = [
  { text: '前稀', value: model.DilutionMethod.PreDilution },
  { text: '後稀', value: model.DilutionMethod.PostDilution },
];

export const hdFrequencyOptions = [
  { text: 'QW', value: model.HDFrequency.QW },
  { text: 'BIW', value: model.HDFrequency.BIW },
  { text: 'TIW', value: model.HDFrequency.TIW },
];

export const dialysateFlowRateOptions = [300, 500, 800];

export const typeOfDialyzerOptions = [
  'FX 60 Classix',
  'FX 80 Classix',
  'FX 100 Classix',
  'FX CorDiax 120',
  'FX 8',
  'FX 10',
  'HdF100S',
  'Revaclear 300',
  'Revaclear 400',
  'Polyflux 140H',
  'Polyflux 210H',
  'FASFLO-PS-15S',
  'FASFLO-PS-18S',
  'FASFLO-PS-21S',
  'Rexeed-25A',
  'BG-1.8U',
  'Absorba 300C',
  'DF-140',
  'LF-030',
  'LF-050',
  'Medopan 30',
  'Medopan 50',
];

export const nameOfHeparinOptions = ['Heparing', 'Fragmin'];

export const orderOfNoHeparinOptions = [
  { text: '長期', value: model.OrderOfNoHeparin.LongTerm },
  { text: '短期', value: model.OrderOfNoHeparin.ShortTerm },
];

export const bodyPartOptions = [
  { text: '左上臂', value: model.BodyPart.LeftUpperArm },
  { text: '右上臂', value: model.BodyPart.RightUpperArm },
  { text: '左前臂', value: model.BodyPart.LeftForearm },
  { text: '右前臂', value: model.BodyPart.RightForearm },
  { text: '左大腿', value: model.BodyPart.LeftThigh },
  { text: '右大腿', value: model.BodyPart.RightThigh },
];

export const typeOfVascularOptions = [
  { text: 'AV fistula', value: model.TypeOfVascular.AVFistula },
  { text: 'AV graft（內動）', value: model.TypeOfVascular.AVGraftInside },
  { text: 'AV graft（外動）', value: model.TypeOfVascular.AVGraftOutside },
  { text: 'AV fistula + graft', value: model.TypeOfVascular.AVFistulaGraft },
];

export const sizeOfPunctureNeedleOptions = [16, 17];

export const specificationOfDLCOptions = [
  '左',
  '右',
  'DL 2way',
  'DL 3way',
  'Permcath',
  '20cm',
  '16cm',
  '13cm',
  'Femoral',
  'JV',
  'SCV',
];

export const statusOfThrillOptions = [
  { text: '有', value: model.StatusOfThrill.DoHave },
  { text: '無', value: model.StatusOfThrill.DoNotHave },
  { text: '搏動', value: model.StatusOfThrill.Pulsation },
];

export const statusOfBruitOptions = [
  { text: '有', value: model.StatusOfBruit.DoHave },
  { text: '無', value: model.StatusOfBruit.DoNotHave },
  { text: 'High pitch', value: model.StatusOfBruit.HighPitch },
];

export const evaluationResultOptions = [
  '正常',
  '發紅',
  '腫脹',
  '發熱',
  '疼痛',
  '蒼白',
  '發紺',
  'pus',
];

export const measureOptions = ['N/S 100mL', '50% G/W 1Amp', '50% G/W 2Amp', '抬高下肢', '暫停HD'];

export const processingNoteOptions = [
  // '洗前',
  // '洗後',
  '上廁所暫停中',
  '重新上機',
];

export const oxygenDeliveryMethodOptions = [
  { text: 'N/C', value: model.OxygenDeliveryMethod.NC },
  { text: 'O₂ mask', value: model.OxygenDeliveryMethod.O2Mask },
  { text: 'T-mask', value: model.OxygenDeliveryMethod.TMask },
  { text: 'NRM', value: model.OxygenDeliveryMethod.NRM },
  { text: '呼吸器', value: model.OxygenDeliveryMethod.Respirator },
];

export const oxygenFlowRateOptions = _.range(1, 16);

export const akClotOptions = _.range(5);

export const chamberClotOptions = ['A', 'V', 'AV'];

export const processedNoteOptions = [
  '透析過程生命徵象穩定，無不適主訴',
  '透析管路正常',
  '處置方式：op site cover',
  '處置方式：白紗cover',
  '透析管路：failure',
  '透析管路：抽吸不順 ',
  '告知當班醫師：入急診處理',
  '告知當班醫師：入門診處理',
];
