type integer = number;
type float = number;
type DN = `${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}`;
type D = '0' | DN;
type YYYY = `${19 | 20}${D}${D}`;
type MM = `0${DN}` | `1${0 | 1 | 2}`;
type DD = `0${DN}` | `${1 | 2}${D}` | `3${0 | 1}`;
type TT = `${0 | 1 | 2 | 3 | 4 | 5}${D}`;
type DateString = `${YYYY}-${MM}-${DD}`;
type TimeString = `${TT}:${TT}`;
type Sex = 1 | 2; // ISO 5218: 1=male, 2=female

export enum Treatment {
  HD = 'HD',
  HDF = 'HDF',
  CVVH = 'CVVH',
  SLEDDf = 'SLEDD-f',
  DFPP = 'DFPP',
  PE = 'PE',
  HP = 'HP',
  MARS = 'MARS',
}

export enum WeightMeasurementMethod {
  Unable = 'unable', // 無法測量
  Sitting = 'sitting', // 坐秤
  Lying = 'lying', // 床秤
  Hanging = 'hanging', // 吊秤
}

export enum Consciousness {
  Sober = 'sober', // 清醒
  Others = 'others', // 其他
}

type E = `E${'4' | '3' | '2' | '1' | 'c'}`;
type V = `V${'5' | '4' | '3' | '2' | '1' | 't' | 'e' | 'a'}`;
type M = `M${'6' | '5' | '4' | '3' | '2' | '1'}`;
type GlasgowComaScale = `${E}${V}${M}`;

export enum DilutionMethod {
  PreDilution = 'pre-dilution', // 前稀
  PostDilution = 'post-dilution', // 後稀
}

export enum HDFrequency {
  QW = 1, // 每週1次
  BIW = 2, // 每週2次
  TIW = 3, // 每週3次
}

export type DialysateFlowRate = 300 | 500 | 800;

export enum OrderOfNoHeparin {
  LongTerm = 'long-term', // 長期
  ShortTerm = 'short-term', // 短期
}

export enum IncludedType {
  Food = 'food', // 食物
  Water = 'water', // 水
  Medication = 'medication', // 藥物
}

export enum BodyPart {
  LeftUpperArm = 'left-upper-arm', // 左上臂
  RightUpperArm = 'right-upper-arm', // 右上臂
  LeftForearm = 'left-forearm', // 左前臂
  RightForearm = 'right-forearm', // 右前臂
  LeftThigh = 'left-thigh', // 左大腿
  RightThigh = 'right-thigh', // 右大腿
}

export enum TypeOfVascular {
  AVFistula = 'av-fistula',
  AVGraftInside = 'av-graft-inside',
  AVGraftOutside = 'av-graft-outside',
  AVFistulaGraft = 'av-fistula-graft',
}

export type SizeOfPunctureNeedle = 16 | 17;

export enum StatusOfThrill {
  DoHave = 'do-have',
  DoNotHave = 'do-not-have',
  Pulsation = 'pulsation',
}

export enum StatusOfBruit {
  DoHave = 'do-have',
  DoNotHave = 'do-not-have',
  HighPitch = 'high-pitch',
}

export enum OxygenDeliveryMethod {
  NC = 'nc',
  O2Mask = 'o2-mask',
  TMask = 't-mask',
  NRM = 'nrm',
  Respirator = 'respirator',
}

export type AKClot = 0 | 1 | 2 | 3 | 4;

export type ChamberClot = 'A' | 'V' | 'AV';

export interface PatientProfile {
  readonly idNumber: string;
  readonly fullName: string;
  readonly birthDate: DateString;
  readonly sex: Sex; // get?
  readonly hospitalBedNumber: string;
}

export interface HDWhenWhere {
  readonly hdDate: DateString;
  readonly hdBedNumber: string;
  readonly hdMachineNumber: string;
}

export interface HDOrientation {
  readonly totalHDTimes: integer;
  treatment: Treatment;
  weightMeasurementMethod: WeightMeasurementMethod;
  consciousness: Consciousness;
  glasgowComaScale: GlasgowComaScale;
  bodyTemperature: float;
}

export interface HDTimeRange {
  beginTime: TimeString;
  endTime: TimeString;
}

export interface Hemodiafiltration {
  dilutionMethod: DilutionMethod;
  infusionRate: integer;
}

export interface HDMachineSetup {
  hdFrequency: HDFrequency;
  hdDuration: integer;
  dialysateFlowRate: DialysateFlowRate;
  typeOfDialyzer: string;
  caIonConcentration: integer;
  kIonConcentration: integer;
  nameOfHeparin: string;
  loadingDoseOfHeparin: integer;
  maintenanceDoseOfHeparin: integer;
  primingDoseOfHeparin: integer;
  orderOfNoHeparin: OrderOfNoHeparin;
  reasonOfNoHeparin: string;
}

export interface PatientWeight {
  afterHDFromLast: float;
  dryWeight: float;
  withoutClothes: float;
  beforeHD: float;
  expectedDehydration: float | null;
  includedTypes: Array<IncludedType>;
  includedWeight: float;
  setDehydration: float;
  afterHD: float;
  actualDehydration: float | null;
}

export interface VascularAccess {
  bodyParts: BodyPart[];
  typeOfVascular: TypeOfVascular;
  sizeOfPunctureNeedle: SizeOfPunctureNeedle;
  specificationsOfDLC: string[];
  statusOfThrill: StatusOfThrill;
  statusOfBruit: StatusOfBruit;
  evaluationResults: string[];
}

export interface HDProcess {
  time: TimeString;
  systolicBloodPressure: integer;
  diastolicBloodPressure: integer;
  pulse: integer;
  bloodFlowRate: integer;
  venousPressure: integer;
  transmembranePressure: integer;
  accumulatedHeparin: integer;
  fluidTemperature: float; // 1
  ultrafiltrationRate: float; // 2
  totalUltrafiltration: float; // 2
  conductivity: float; // 1
  dialysateFlowRate: float; // 2
  substitutionVolume: integer;
  measures: string[];
  processingNote: string;
}

export interface HDProcessNote {
  oxygenDeliveryMethod: OxygenDeliveryMethod;
  oxygenFlowRate: integer;
  bloodOxygenSaturationLevel: integer;
  isPipelineSafe: boolean;
  isBedRailUsed: boolean;
  akClot: AKClot;
  chamberClot: ChamberClot;
  processedNotes: Array<string>;
  noteFromPhysician: string;
}

export interface NursingRecord {
  patientProfile: PatientProfile;
  hdWhenWhere: HDWhenWhere;
  hdOrientation: HDOrientation;
  hdTimeRange: HDTimeRange;
  hemodiafiltration: Hemodiafiltration;
  hdMachineSetup: HDMachineSetup;
  patientWeight: PatientWeight;
  vascularAccess: VascularAccess;
  hdProcess: HDProcess[];
  hdProcessNote: HDProcessNote;
}
