<template>
  <VForm :value="value" @input="$emit('input', $event)">
    <VCard>
      <div class="subtitle-2 text-center">傷口紀錄（部分功能）</div>
      <VDivider />
      <VRow>
        <VCol>
          <VRadioGroup v-model="radioGroup" dense class="v-input--hide-label">
            <VRadio v-for="(v, i) in radioGroupItems" :key="i" :label="v" :value="i" />
          </VRadioGroup>
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <VImg :src="require('@/assets/images/body.svg')" contain />
        </VCol>
      </VRow>
      <VBtn
        icon
        x-small
        absolute
        style="top: 9px; right: 9px"
        :disabled="radioGroup !== 0"
        @click="dialogs.woundRecord = true"
      >
        <VIcon>mdi-pencil</VIcon>
      </VBtn>
    </VCard>
    <VDialog v-model="dialogs.woundRecord" max-width="600px">
      <VCard>
        <VToolbar color="primary" dark dense>
          <VIcon left>mdi-pencil</VIcon>
          <VToolbarTitle>編輯傷口紀錄</VToolbarTitle>
          <VSpacer />
          <VBtn icon @click="dialogs.woundRecord = false">
            <VIcon>mdi-close</VIcon>
          </VBtn>
          <template #extension>
            <VSpacer />
            <VBtnToggle dense group>
              <VBtn text>
                <VIcon>mdi-brush</VIcon>
              </VBtn>
              <VBtn text>
                <VIcon>mdi-circle-outline</VIcon>
              </VBtn>
              <VBtn text>
                <VIcon>mdi-rectangle-outline</VIcon>
              </VBtn>
              <VBtn text>
                <VIcon>mdi-eraser-variant</VIcon>
              </VBtn>
            </VBtnToggle>
            <VDivider vertical />
            <VBtnToggle dense group multiple :max="0">
              <VBtn text>
                <VIcon left>mdi-palette</VIcon>
                紅色
              </VBtn>
              <VBtn text>
                <VIcon left>mdi-format-line-weight</VIcon>
                細
              </VBtn>
            </VBtnToggle>
            <VDivider vertical />
            <VBtnToggle dense group multiple :max="0">
              <VBtn text>
                <VIcon left>mdi-redo</VIcon>
                重做
              </VBtn>
              <VBtn text>
                <VIcon left>mdi-undo</VIcon>
                復原
              </VBtn>
            </VBtnToggle>
            <VSpacer />
          </template>
        </VToolbar>
        <VContainer>
          <VImg :src="require('@/assets/images/body.svg')" />
        </VContainer>
      </VCard>
    </VDialog>
  </VForm>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'WoundRecordForm',
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, required: true },
  },
  data: () => ({
    inputs: null,
    dialogs: {
      woundRecord: false,
    },
    // TODO
    radioGroup: 0,
    radioGroupItems: ['有傷口', '無傷口', '住院病患參照各單位評估表單'],
  }),
  created() {
    this.inputs = this.loadModelToInputs(this.data.hdProcess);
  },
  methods: {
    loadModelToInputs(model) {
      const inputs = _.cloneDeep(model);
      return inputs;
    },
  },
});
</script>
