<template>
  <VForm :value="value" @input="$emit('input', $event)">
    <VCard>
      <div class="subtitle-2 text-center">體重</div>
      <VDivider />
      <VRow>
        <VCol class="col-180-39">
          <XTextField
            v-model="inputs.afterHDFromLast"
            type="number"
            label="上次洗後體重"
            suffix="kg"
            readonly
            :rules="rules.afterHDFromLast"
          />
        </VCol>
        <VCol class="col-180-39">
          <XTextField
            v-model="inputs.dryWeight"
            type="number"
            label="乾體重"
            suffix="kg"
            readonly
            :rules="rules.dryWeight"
          />
        </VCol>
        <VCol class="col-180-34">
          <XTextField
            v-model="inputs.beforeHD"
            type="number"
            label="洗前體重"
            suffix="kg"
            :rules="rules.beforeHD"
            :disabled="!canWeightBeMeasured"
          />
        </VCol>
        <VCol class="col-180-34">
          <XTextField
            v-model="inputs.withoutClothes"
            type="number"
            label="扣除衣物"
            suffix="kg"
            :rules="rules.withoutClothes"
          />
        </VCol>
        <VCol class="col-180-34">
          <VMenu
            v-if="canWeightBeMeasured"
            v-model="menus.expectedDehydration"
            :close-on-content-click="false"
            :disabled="!isComputedExpectedDehydrationValid"
            offset-y
            max-width="96px"
          >
            <template #activator="{ on, attrs }">
              <VTextField
                v-model="expectedDehydration"
                type="number"
                label="應脫水"
                suffix="kg"
                dense
                hide-details
                readonly
                :rules="rules.expectedDehydration"
                :background-color="isExpectedDehydrationValid ? 'warning lighten-4' : undefined"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <VCard class="px-2 pt-4">
              <VRow>
                <VCol>
                  <XTextField
                    v-model="correctedExpectedDehydration"
                    type="number"
                    label="更正"
                    suffix="kg"
                    :rules="rules.expectedDehydration"
                  />
                </VCol>
              </VRow>
              <VCardActions class="px-0 py-2">
                <VTooltip bottom>
                  <template #activator="{ on, attrs }">
                    <VBtn
                      icon
                      color="primary"
                      :disabled="!isCorrectedExpectedDehydrationValid"
                      v-bind="attrs"
                      v-on="on"
                      @click="setExpectedDehydration()"
                    >
                      <VIcon>mdi-check</VIcon>
                    </VBtn>
                  </template>
                  確定
                </VTooltip>
                <VSpacer />
                <VTooltip bottom>
                  <template #activator="{ on, attrs }">
                    <VBtn
                      icon
                      color="warning"
                      v-bind="attrs"
                      v-on="on"
                      @click="setExpectedDehydration(true)"
                    >
                      <VIcon>mdi-refresh</VIcon>
                    </VBtn>
                  </template>
                  重設
                </VTooltip>
              </VCardActions>
            </VCard>
          </VMenu>
          <XTextField
            v-else
            v-model="inputs.expectedDehydration"
            type="number"
            label="實脫水"
            suffix="kg"
            :rules="rules.expectedDehydration"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol class="col-180-64">
          <XSelect
            v-model="inputs.includedTypes"
            multiple
            label="內含"
            :items="options.includedTypes"
          />
        </VCol>
        <VCol class="col-180-24">
          <XTextField
            v-model="inputs.includedWeight"
            type="number"
            label="內含重量"
            suffix="kg"
            :rules="rules.includedWeight"
            :disabled="inputs.includedTypes.length === 0"
          />
        </VCol>
        <VCol class="col-180-24">
          <XTextField
            v-model="inputs.setDehydration"
            type="number"
            label="脫水設定"
            suffix="kg"
            :rules="rules.setDehydration"
          />
        </VCol>
        <VCol class="col-180-34">
          <XTextField
            v-model="inputs.afterHD"
            type="number"
            label="洗後體重"
            suffix="kg"
            :rules="rules.afterHD"
            :disabled="!canWeightBeMeasured"
          />
        </VCol>
        <VCol class="col-180-34">
          <VMenu
            v-if="canWeightBeMeasured"
            v-model="menus.actualDehydration"
            :close-on-content-click="false"
            :disabled="!isComputedActualDehydrationValid"
            offset-y
            max-width="96px"
          >
            <template #activator="{ on, attrs }">
              <VTextField
                v-model="actualDehydration"
                type="number"
                label="實脫水"
                suffix="kg"
                dense
                hide-details
                readonly
                :rules="rules.actualDehydration"
                :background-color="isActualDehydrationValid ? 'warning lighten-4' : undefined"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <VCard class="px-2 pt-4">
              <VRow>
                <VCol>
                  <XTextField
                    v-model="correctedActualDehydration"
                    type="number"
                    label="更正"
                    suffix="kg"
                    :rules="rules.actualDehydration"
                  />
                </VCol>
              </VRow>
              <VCardActions class="px-0 py-2">
                <VTooltip bottom>
                  <template #activator="{ on, attrs }">
                    <VBtn
                      icon
                      color="primary"
                      :disabled="!isCorrectedActualDehydrationValid"
                      v-bind="attrs"
                      v-on="on"
                      @click="setActualDehydration()"
                    >
                      <VIcon>mdi-check</VIcon>
                    </VBtn>
                  </template>
                  確定
                </VTooltip>
                <VSpacer />
                <VTooltip bottom>
                  <template #activator="{ on, attrs }">
                    <VBtn
                      icon
                      color="warning"
                      v-bind="attrs"
                      v-on="on"
                      @click="setActualDehydration(true)"
                    >
                      <VIcon>mdi-refresh</VIcon>
                    </VBtn>
                  </template>
                  重設
                </VTooltip>
              </VCardActions>
            </VCard>
          </VMenu>
          <XTextField
            v-else
            v-model="inputs.actualDehydration"
            type="number"
            label="實脫水"
            suffix="kg"
            :rules="rules.actualDehydration"
          />
        </VCol>
      </VRow>
    </VCard>
  </VForm>
</template>

<script>
import Vue from 'vue';

import * as inputRules from '@/utils/inputRules';

import { includedTypeOptions } from './options';

export default Vue.extend({
  name: 'PatientWeightForm',
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, required: true },
  },
  data: () => ({
    inputs: null,
    options: {
      includedTypes: includedTypeOptions,
    },
    rules: {
      afterHDFromLast: [inputRules.required, (v) => inputRules.numberString(v, 1)],
      dryWeight: [inputRules.required, (v) => inputRules.numberString(v, 1)],
      withoutClothes: [(v) => inputRules.numberString(v, 1)],
      beforeHD: [inputRules.required, (v) => inputRules.numberString(v, 1)],
      expectedDehydration: [inputRules.required, (v) => inputRules.numberString(v, 1)],
      includedWeight: [inputRules.required, (v) => inputRules.numberString(v, 1)],
      setDehydration: [inputRules.required, (v) => inputRules.numberString(v, 1)],
      afterHD: [inputRules.required, (v) => inputRules.numberString(v, 1)],
      actualDehydration: [inputRules.required, (v) => inputRules.numberString(v, 1)],
    },
    menus: {
      expectedDehydration: false,
      actualDehydration: false,
    },
    correctedExpectedDehydration: null,
    correctedActualDehydration: null,
  }),
  computed: {
    canWeightBeMeasured() {
      return this.data.hdOrientation.weightMeasurementMethod !== 'unable';
    },
    expectedDehydration() {
      if (this.isExpectedDehydrationValid) return this.inputs.expectedDehydration;
      if (!this.isComputedExpectedDehydrationValid) return '';
      const beforeHD = parseFloat(this.inputs.beforeHD);
      const dryWeight = parseFloat(this.inputs.dryWeight);
      const withoutClothes = parseFloat(this.inputs.withoutClothes);
      return (beforeHD - dryWeight - withoutClothes).toFixed(1);
    },
    actualDehydration() {
      if (this.isActualDehydrationValid) return this.inputs.actualDehydration;
      if (!this.isComputedActualDehydrationValid) return '';
      const beforeHD = parseFloat(this.inputs.beforeHD);
      const afterHD = parseFloat(this.inputs.afterHD);
      const withoutClothes = parseFloat(this.inputs.withoutClothes);
      return (beforeHD - afterHD - withoutClothes).toFixed(1);
    },
    isExpectedDehydrationValid() {
      const [rule, input] = [this.rules.expectedDehydration, this.inputs.expectedDehydration];
      return inputRules.test(rule, input) === true;
    },
    isCorrectedExpectedDehydrationValid() {
      const [rule, input] = [this.rules.expectedDehydration, this.correctedExpectedDehydration];
      return inputRules.test(rule, input) === true;
    },
    isComputedExpectedDehydrationValid() {
      return ['beforeHD', 'dryWeight', 'withoutClothes']
        .map((k) => inputRules.test(this.rules[k], this.inputs[k]))
        .every((v) => v === true);
    },
    isActualDehydrationValid() {
      const [rule, input] = [this.rules.actualDehydration, this.inputs.actualDehydration];
      return inputRules.test(rule, input) === true;
    },
    isCorrectedActualDehydrationValid() {
      const [rule, input] = [this.rules.expectedDehydration, this.correctedActualDehydration];
      return inputRules.test(rule, input) === true;
    },
    isComputedActualDehydrationValid() {
      return ['beforeHD', 'afterHD', 'withoutClothes']
        .map((k) => inputRules.test(this.rules[k], this.inputs[k]))
        .every((v) => v === true);
    },
  },
  watch: {
    inputs: {
      handler: 'updateModelFromInputs',
      deep: true,
    },
    canWeightBeMeasured(newValue) {
      if (newValue) {
        this.inputs.expectedDehydration = null;
        this.inputs.actualDehydration = null;
      } else {
        this.inputs.beforeHD = null;
        this.inputs.afterHD = null;
      }
    },
    ['inputs.includedTypes'](newValue) {
      if (newValue.length === 0) this.inputs.includedWeight = null;
    },
  },
  created() {
    this.inputs = this.loadModelToInputs(this.data.patientWeight);
  },
  methods: {
    loadModelToInputs(model) {
      const inputs = _.cloneDeep(model);
      inputs.afterHDFromLast = model.afterHDFromLast?.toFixed(1);
      inputs.dryWeight = model.dryWeight?.toFixed(1);
      inputs.withoutClothes = model.withoutClothes?.toFixed(1);
      inputs.beforeHD = model.beforeHD?.toFixed(1);
      inputs.expectedDehydration = model.expectedDehydration?.toFixed(1);
      inputs.includedWeight = model.includedWeight?.toFixed(1);
      inputs.setDehydration = model.setDehydration?.toFixed(1);
      inputs.afterHD = model.afterHD?.toFixed(1);
      inputs.actualDehydration = model.actualDehydration?.toFixed(1);
      return inputs;
    },
    updateModelFromInputs(inputs) {
      const model = _.cloneDeep(inputs);
      model.afterHDFromLast = parseFloat(inputs.afterHDFromLast);
      model.dryWeight = parseFloat(inputs.dryWeight);
      model.withoutClothes = inputRules.isNonEmptyString(inputs.withoutClothes)
        ? parseFloat(inputs.withoutClothes)
        : null; // TODO: need helper function
      model.beforeHD = parseFloat(inputs.beforeHD);
      model.expectedDehydration = parseFloat(inputs.expectedDehydration);
      model.includedWeight =
        inputs.includedTypes.length > 0 ? parseFloat(inputs.includedWeight) : null;
      model.setDehydration = parseFloat(inputs.setDehydration);
      model.afterHD = parseFloat(inputs.afterHD);
      model.actualDehydration = parseFloat(inputs.actualDehydration);
      this.$emit('update', model);
    },
    setExpectedDehydration(isReset = false) {
      if (isReset) {
        this.inputs.expectedDehydration = null;
        this.correctedExpectedDehydration = null;
      } else {
        this.inputs.expectedDehydration = this.correctedExpectedDehydration;
      }
      this.menus.expectedDehydration = false;
    },
    setActualDehydration(isReset = false) {
      if (isReset) {
        this.inputs.actualDehydration = null;
        this.correctedActualDehydration = null;
      } else {
        this.inputs.actualDehydration = this.correctedActualDehydration;
      }
      this.menus.actualDehydration = false;
    },
  },
});
</script>
