<template>
  <VForm :value="value" @input="$emit('input', $event)">
    <VCard>
      <VRow>
        <VCol class="col-180-84">
          <XTextField v-model="inputs.hdDate" label="透析日期" :rules="rules.hdDate" readonly />
        </VCol>
        <VCol class="col-180-48">
          <XTextField
            v-model="inputs.hdBedNumber"
            label="透析床號"
            :rules="rules.hdBedNumber"
            readonly
          />
        </VCol>
        <VCol class="col-180-48">
          <XTextField
            v-model="inputs.hdMachineNumber"
            label="透析機號"
            :rules="rules.hdMachineNumber"
            readonly
          />
        </VCol>
      </VRow>
    </VCard>
  </VForm>
</template>

<script>
import Vue from 'vue';

import * as inputRules from '@/utils/inputRules';

export default Vue.extend({
  name: 'HDWhenWhereForm',
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, required: true },
  },
  data: () => ({
    models: null,
    rules: {
      hdDate: [inputRules.required],
      hdBedNumber: [inputRules.required],
      hdMachineNumber: [inputRules.required],
    },
  }),
  created() {
    this.inputs = this.loadModelToInputs(this.data.hdWhenWhere);
  },
  methods: {
    loadModelToInputs(model) {
      const inputs = _.cloneDeep(model);
      inputs.hdDate = this.dayjs(model.hdDate).format('L');
      return inputs;
    },
  },
});
</script>
