<template>
  <VForm :value="value" @input="$emit('input', $event)">
    <VCard>
      <div class="subtitle-2 text-center">透析過程紀錄</div>
      <VDivider />
      <VRow no-gutters>
        <VCol cols="5">
          <VRow>
            <VCol cols="auto">
              <VCheckbox
                v-model="inputs.isPipelineSafe"
                class="v-input--hide-label"
                dense
                hide-details
                label="管路安全評估"
              />
            </VCol>
            <VCol cols="auto">
              <VCheckbox
                v-model="inputs.isBedRailUsed"
                class="v-input--hide-label"
                dense
                hide-details
                label="床欄使用"
              />
            </VCol>
            <VCol cols="auto">
              <!-- TODO: v-model -->
              <VCheckbox
                class="v-input--hide-label"
                dense
                hide-details
                label="透析機警示功能運作正常"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol class="col-180-51">
              <XSelect
                v-model="inputs.oxygenDeliveryMethod"
                label="氧氣使用種類"
                clearable
                :items="options.oxygenDeliveryMethod"
              />
            </VCol>
            <VCol class="col-180-42">
              <XSelect
                v-model="inputs.oxygenFlowRate"
                type="number"
                label="氧氣流量"
                suffix="L/min"
                :items="options.oxygenFlowRate"
                :disabled="!hasOxygenDeliveryMethod"
              />
            </VCol>
            <VCol class="col-180-27">
              <XTextField
                v-model="inputs.bloodOxygenSaturationLevel"
                label="血氧飽和度"
                type="number"
                suffix="%"
                :rules="rules.bloodOxygenSaturationLevel"
                :disabled="!hasOxygenDeliveryMethod"
              />
            </VCol>
            <VCol class="col-180-27">
              <XSelect
                v-model="inputs.akClot"
                type="number"
                label="AK clot"
                :items="options.akClot"
                suffix="價"
              />
            </VCol>
            <VCol class="col-180-33">
              <XSelect
                v-model="inputs.chamberClot"
                label="Chamber clot"
                clearable
                :items="options.chamberClot"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VTextarea
                v-model="inputs.noteFromPhysician"
                label="醫師記錄"
                dense
                no-resize
                hide-details
                rows="1"
              />
            </VCol>
          </VRow>
        </VCol>
        <VCol cols="7">
          <VRow>
            <VCol>
              <XCombobox
                v-model="inputs.processedNotes"
                label="紀錄事項"
                multiple
                clearable
                :items="options.processedNotes"
              />
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VCard>
  </VForm>
</template>

<script>
import Vue from 'vue';

import * as inputRules from '@/utils/inputRules';

import {
  akClotOptions,
  chamberClotOptions,
  oxygenDeliveryMethodOptions,
  oxygenFlowRateOptions,
  processedNoteOptions,
} from './options';

export default Vue.extend({
  name: 'HDProcessNoteForm',
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, required: true },
  },
  data: () => ({
    inputs: null,
    options: {
      oxygenDeliveryMethod: oxygenDeliveryMethodOptions,
      oxygenFlowRate: oxygenFlowRateOptions,
      akClot: akClotOptions,
      chamberClot: chamberClotOptions,
      processedNotes: processedNoteOptions,
    },
    rules: {
      bloodOxygenSaturationLevel: [inputRules.required, (v) => inputRules.numberString(v, 0)],
    },
  }),
  computed: {
    hasOxygenDeliveryMethod() {
      return !_.isNil(this.inputs?.oxygenDeliveryMethod);
    },
  },
  watch: {
    inputs: {
      handler: 'updateModelFromInputs',
      deep: true,
    },
    hasOxygenDeliveryMethod(newValue) {
      if (!newValue) {
        this.inputs.oxygenFlowRate = null;
        this.inputs.bloodOxygenSaturationLevel = null;
      }
    },
  },
  created() {
    this.inputs = this.loadModelToInputs(this.data.hdMachineSetup);
  },
  methods: {
    loadModelToInputs(model) {
      const inputs = _.cloneDeep(model);
      inputs.hdDuration = model.hdDuration?.toFixed(0);
      inputs.bloodOxygenSaturationLevel = model.caIonConcentration?.toFixed(0);
      return inputs;
    },
    updateModelFromInputs(inputs) {
      const model = _.cloneDeep(inputs);
      model.bloodOxygenSaturationLevel = parseInt(inputs.hdDuration, 10);
      this.$emit('update', model);
    },
  },
});
</script>
