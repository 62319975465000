<template>
  <VForm :value="value" @input="$emit('input', $event)">
    <VCard>
      <div class="subtitle-2 text-center">透析機設置</div>
      <VDivider />
      <VRow>
        <VCol class="col-180-24">
          <XSelect v-model="inputs.hdFrequency" label="透析頻率" :items="options.hdFrequency" />
        </VCol>
        <VCol class="col-180-24">
          <XTextField
            v-model="inputs.hdDuration"
            type="number"
            label="透析時長"
            suffix="小時"
            :rules="rules.hdDuration"
          />
        </VCol>
        <VCol class="col-180-36">
          <XSelect
            v-model="inputs.dialysateFlowRate"
            type="number"
            label="透析液流速"
            suffix="mL/分"
            :items="options.dialysateFlowRate"
          />
        </VCol>
        <VCol class="col-180-48">
          <XSelect
            v-model="inputs.typeOfDialyzer"
            label="透析器種類"
            :items="options.typeOfDialyzer"
          />
        </VCol>
        <VCol class="col-180-24">
          <XTextField
            v-model="inputs.caIonConcentration"
            type="number"
            label="鈣離子濃度"
            suffix="mol/L"
            :rules="rules.caIonConcentration"
          />
        </VCol>
        <VCol class="col-180-24">
          <XTextField
            v-model="inputs.kIonConcentration"
            type="number"
            label="鉀離子濃度"
            suffix="mol/L"
            :rules="rules.kIonConcentration"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol class="col-180-36">
          <XCombobox v-model="inputs.nameOfHeparin" label="肝素" :items="options.nameOfHeparin" />
        </VCol>
        <VCol class="col-180-18">
          <XTextField
            v-model="inputs.loadingDoseOfHeparin"
            type="number"
            label="初劑量"
            suffix="u"
            :rules="rules.loadingDoseOfHeparin"
          />
        </VCol>
        <VCol class="col-180-18">
          <XTextField
            v-model="inputs.maintenanceDoseOfHeparin"
            type="number"
            label="維持量"
            suffix="u"
            :rules="rules.maintenanceDoseOfHeparin"
          />
        </VCol>
        <VCol class="col-180-18">
          <XTextField
            v-model="inputs.primingDoseOfHeparin"
            type="number"
            label="Priming"
            suffix="u"
            :rules="rules.primingDoseOfHeparin"
          />
        </VCol>
        <VCol class="col-180-30">
          <XSelect
            v-model="inputs.orderOfNoHeparin"
            label="無肝素order"
            clearable
            :items="options.orderOfNoHeparin"
          />
        </VCol>
        <VCol class="col-180-60">
          <XTextField
            v-model="inputs.reasonOfNoHeparin"
            label="無肝素原因"
            :rules="rules.reasonOfNoHeparin"
            :disabled="inputs.orderOfNoHeparin !== 'short-term'"
          />
        </VCol>
      </VRow>
    </VCard>
  </VForm>
</template>

<script>
import Vue from 'vue';

import * as inputRules from '@/utils/inputRules';

import {
  dialysateFlowRateOptions,
  hdFrequencyOptions,
  nameOfHeparinOptions,
  orderOfNoHeparinOptions,
  typeOfDialyzerOptions,
} from './options';

export default Vue.extend({
  name: 'HDMachineSetupForm',
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, required: true },
  },
  data: () => ({
    inputs: null,
    options: {
      hdFrequency: hdFrequencyOptions,
      dialysateFlowRate: dialysateFlowRateOptions,
      typeOfDialyzer: typeOfDialyzerOptions,
      nameOfHeparin: nameOfHeparinOptions,
      orderOfNoHeparin: orderOfNoHeparinOptions,
    },
    rules: {
      hdDuration: [inputRules.required, (v) => inputRules.numberString(v, 0)],
      caIonConcentration: [inputRules.required, (v) => inputRules.numberString(v, 0)],
      kIonConcentration: [inputRules.required, (v) => inputRules.numberString(v, 0)],
      loadingDoseOfHeparin: [inputRules.required, (v) => inputRules.numberString(v, 0)],
      maintenanceDoseOfHeparin: [inputRules.required, (v) => inputRules.numberString(v, 0)],
      primingDoseOfHeparin: [(v) => inputRules.numberString(v, 0)],
      reasonOfNoHeparin: [inputRules.required],
    },
    correctedExpectedDehydration: null,
  }),
  watch: {
    inputs: {
      handler: 'updateModelFromInputs',
      deep: true,
    },
  },
  created() {
    this.inputs = this.loadModelToInputs(this.data.hdMachineSetup);
  },
  methods: {
    loadModelToInputs(model) {
      const inputs = _.cloneDeep(model);
      inputs.hdDuration = model.hdDuration?.toFixed(0);
      inputs.caIonConcentration = model.caIonConcentration?.toFixed(0);
      inputs.kIonConcentration = model.kIonConcentration?.toFixed(0);
      inputs.loadingDoseOfHeparin = model.loadingDoseOfHeparin?.toFixed(0);
      inputs.maintenanceDoseOfHeparin = model.maintenanceDoseOfHeparin?.toFixed(0);
      inputs.primingDoseOfHeparin = model.primingDoseOfHeparin?.toFixed(0);
      return inputs;
    },
    updateModelFromInputs(inputs) {
      const model = _.cloneDeep(inputs);
      model.hdDuration = parseInt(inputs.hdDuration, 10);
      model.caIonConcentration = parseInt(inputs.caIonConcentration, 10);
      model.kIonConcentration = parseInt(inputs.kIonConcentration, 10);
      model.loadingDoseOfHeparin = parseInt(inputs.loadingDoseOfHeparin, 10);
      model.maintenanceDoseOfHeparin = parseInt(inputs.maintenanceDoseOfHeparin, 10);
      model.primingDoseOfHeparin = inputRules.isNonEmptyString(inputs.primingDoseOfHeparin)
        ? parseInt(inputs.primingDoseOfHeparin, 10)
        : null;
      this.$emit('update', model);
    },
  },
});
</script>
