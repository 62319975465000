<template>
  <VForm :value="value" @input="$emit('input', $event)">
    <VCard>
      <div class="subtitle-2 text-center">血管通路</div>
      <VDivider />
      <VRow>
        <VCol>
          <XSelect v-model="inputs.bodyParts" label="部位" multiple :items="options.bodyParts" />
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <XSelect v-model="inputs.typeOfVascular" label="種類" :items="options.typeOfVascular" />
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <XSelect
            v-model="inputs.specificationsOfDLC"
            label="暫時性及中長期雙腔導管"
            multiple
            :items="options.specificationsOfDLC"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <XSelect
            v-model="inputs.sizeOfPunctureNeedle"
            type="number"
            label="穿刺針"
            clearable
            suffix="號"
            :items="options.sizeOfPunctureNeedle"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="5">
          <XSelect v-model="inputs.statusOfThrill" label="Thrill" :items="options.statusOfThrill" />
        </VCol>
        <VCol cols="7">
          <XSelect v-model="inputs.statusOfBruit" label="Bruit" :items="options.statusOfBruit" />
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <XCombobox
            v-model="inputs.evaluationResults"
            label="管路側評值"
            multiple
            :items="options.evaluationResults"
          />
        </VCol>
      </VRow>
    </VCard>
  </VForm>
</template>

<script>
import Vue from 'vue';

import {
  bodyPartOptions,
  evaluationResultOptions,
  sizeOfPunctureNeedleOptions,
  specificationOfDLCOptions,
  statusOfBruitOptions,
  statusOfThrillOptions,
  typeOfVascularOptions,
} from './options';

export default Vue.extend({
  name: 'VascularAccessForm',
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, required: true },
  },
  data: () => ({
    inputs: null,
    options: {
      bodyParts: bodyPartOptions,
      typeOfVascular: typeOfVascularOptions,
      sizeOfPunctureNeedle: sizeOfPunctureNeedleOptions,
      specificationsOfDLC: specificationOfDLCOptions,
      statusOfThrill: statusOfThrillOptions,
      statusOfBruit: statusOfBruitOptions,
      evaluationResults: evaluationResultOptions,
    },
  }),
  watch: {
    inputs: {
      handler: 'updateModelFromInputs',
      deep: true,
    },
  },
  created() {
    this.inputs = this.loadModelToInputs(this.data.vascularAccess);
  },
  methods: {
    loadModelToInputs(model) {
      const inputs = _.cloneDeep(model);
      return inputs;
    },
    updateModelFromInputs(inputs) {
      const model = _.cloneDeep(inputs);
      this.$emit('update', model);
    },
  },
});
</script>
