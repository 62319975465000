<template>
  <VForm :value="value" @input="$emit('input', $event)">
    <VCard>
      <VRow>
        <VCol class="col-180-42">
          <XTextField
            v-model="inputs.idNumber"
            label="身分證／居留證號"
            readonly
            :rules="rules.idNumber"
          />
        </VCol>
        <VCol class="col-180-60">
          <XTextField v-model="inputs.fullName" label="姓名" readonly :rules="rules.fullName" />
        </VCol>
        <VCol class="col-180-39">
          <XTextField v-model="inputs.birthDate" label="生日" readonly :rules="rules.birthDate" />
        </VCol>
        <VCol class="col-180-15">
          <XTextField v-model="inputs.sex" label="性別" readonly :rules="rules.sex" />
        </VCol>
        <VCol class="col-180-24">
          <XTextField
            v-model="inputs.hospitalBedNumber"
            label="住院床號"
            readonly
            :rules="rules.hospitalBedNumber"
            :disabled="_.isNil(inputs.hospitalBedNumber)"
          />
        </VCol>
      </VRow>
    </VCard>
  </VForm>
</template>

<script>
import Vue from 'vue';

import * as inputRules from '@/utils/inputRules';

export default Vue.extend({
  name: 'PatientProfileForm',
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, required: true },
  },
  data: () => ({
    inputs: null,
    rules: {
      idNumber: [inputRules.required, inputRules.idNumber],
      fullName: [inputRules.required],
      birthDate: [inputRules.required],
      sex: [inputRules.required],
      hospitalBedNumber: [inputRules.required],
    },
  }),
  created() {
    this.inputs = this.loadModelToInputs(this.data.patientProfile);
  },
  methods: {
    loadModelToInputs(model) {
      const inputs = _.cloneDeep(model);
      inputs.birthDate = this.dayjs(model.birthDate).format('L');
      inputs.sex = { 1: '男', 2: '女' }[model.sex];
      return inputs;
    },
  },
});
</script>
